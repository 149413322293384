import React from 'react'
import { StoreContext } from '../../contexts/store/StoreContext'
import styled from 'styled-components'
import * as vars from '../variables'

const HeartCart = () => {
  const { cart, openCart } = React.useContext(StoreContext)

  const lineItems = cart.items

  let totalCount = 0
  if (lineItems.length > 0) {
    lineItems.forEach((p) => {
      totalCount += p.quantity
    })
  }

  const keyDownHeart = (e) => {
    if (e.keyCode === 13) {
      openCart()
    }
  }

  return (
    <StyledHeartCart>
      <div className="cart-heart-container" title="Open cart" alt="Open Cart">
        <span className={totalCount > 9 ? 'total-count whoah' : 'total-count'}>
          {totalCount > 0 ? totalCount : ''}
        </span>
        <svg
          onClick={() => openCart()}
          title="Open cart"
          alt="Open Cart"
          role="button"
          onKeyDown={(e) => keyDownHeart(e)}
          tabIndex={0}
          className="cart-heart"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 1000"
          display="absolute">
          <path d="M814.1 61.5c.2.1.5.2.7.3-.2-.2-.4-.2-.7-.3zM529.9 182.3c4.1-5.9 8-12 11.9-17.9 4.8-7.5 9.8-15.2 15-22.3 23.2-31.7 48.8-55.5 78.1-72.6 3.5-2 7.1-4 10.7-5.8 25.1-12.5 52.6-18.8 81.8-18.9h.6c-33.7 0-65 8.3-93.1 24.7-29.3 17.2-54.9 40.9-78.1 72.6-5.2 7.1-10.2 14.8-15 22.3-3.9 5.9-7.8 12-11.9 17.9-5.9 8.5-14.4 13.9-24 15.5v756.3-756.3c9.5-1.5 18.1-7 24-15.5z" />
          <path d="M727.4 44.8c-29.3.1-56.7 6.4-81.8 18.9 25.1-12.4 52.5-18.8 81.8-18.9zM760.3 47.1c.1 0 .1 0 0 0-.4-.1-.8-.1-1.3-.2.5.1.9.2 1.3.2zM788.8 52.9c-1.3-.4-2.7-.7-4.1-1.1 1.4.4 2.7.7 4.1 1.1zM782.4 51.3c-1.3-.3-2.7-.6-4.1-.9 1.3.2 2.7.5 4.1.9zM768.1 48.4c-.6-.1-1.3-.2-1.9-.3.7 0 1.3.1 1.9.3zM775.6 49.8c-1.4-.3-2.8-.6-4.2-.8 1.4.2 2.8.5 4.2.8zM802.2 57c-1.8-.6-3.5-1.2-5.3-1.7 1.8.5 3.5 1.1 5.3 1.7zM808.8 59.3c-1.9-.7-3.7-1.4-5.6-2 1.9.7 3.7 1.3 5.6 2zM795.5 54.8c-1.7-.5-3.4-1-5.2-1.5 1.7.5 3.5 1 5.2 1.5zM759.1 47c-3.8-.5-7.7-.9-11.6-1.3 3.9.3 7.8.8 11.6 1.3zM778.2 50.3l-2.7-.6c1 .2 1.9.4 2.7.6zM771.4 48.9c-1.1-.2-2.2-.4-3.2-.6 1 .2 2.1.4 3.2.6zM766.2 48c-2-.3-3.9-.6-5.9-.9 2 .3 4 .6 5.9.9zM796.9 55.3c-.5-.1-.9-.3-1.4-.4.5.1.9.2 1.4.4zM803.2 57.3c-.3-.1-.7-.2-1-.3.3.1.6.2 1 .3zM784.7 51.8c-.8-.2-1.5-.4-2.3-.6h-.1c.9.3 1.6.5 2.4.6zM790.3 53.3c-.5-.1-1-.3-1.5-.4.5.1 1 .3 1.5.4z" />
          <path d="M790.3 53.3c1.7.5 3.5 1 5.2 1.5-1.7-.5-3.5-1-5.2-1.5zM796.9 55.3c1.8.6 3.5 1.1 5.3 1.7-1.8-.6-3.5-1.2-5.3-1.7zM814.9 61.8c1.8.7 3.6 1.5 5.4 2.3-1.8-.8-3.6-1.6-5.4-2.3zM808.8 59.3c-1.8-.7-3.7-1.4-5.6-2 2.2.8 4.5 1.6 6.7 2.4-.4-.1-.8-.2-1.1-.4zM784.7 51.8c1.4.3 2.7.7 4 1-1.3-.3-2.6-.6-4-1zM634.9 69.5c3.5-2 7.1-4 10.7-5.8-3.6 1.8-7.2 3.7-10.7 5.8zM728 44.8h-.6.6zM759.1 47c.4.1.8.1 1.2.2-.4-.1-.8-.2-1.2-.2zM778.2 50.3c1.4.3 2.8.6 4.1.9-1.3-.3-2.7-.6-4.1-.9zM766.2 48c.6.1 1.3.2 1.9.3-.6-.1-1.2-.2-1.9-.3zM771.4 48.9c1.4.3 2.8.5 4.2.8-1.4-.2-2.8-.5-4.2-.8zM821.4 64.6l5.1 2.4c-1.6-.8-3.4-1.6-5.1-2.4zM839 73.6c1.6.9 3.3 1.9 4.9 2.9-1.6-1-3.2-1.9-4.9-2.9zM827.4 67.4c1.5.8 3.1 1.5 4.6 2.3-1.5-.7-3-1.5-4.6-2.3zM832.9 70.3c1.7.9 3.3 1.8 5 2.7-1.6-1-3.3-1.9-5-2.7z" />
          <path
            d="M985.6 225.1C971 147.9 929.4 87.9 862.2 46.9c-34.6-21.2-74-33.4-117.2-36.3-5.7-.4-11.5-.6-17.1-.6-39.9 0-77.2 9.9-110.6 29.4-33.5 19.6-62.5 46.5-88.6 82.1-5.8 7.9-11.1 16.1-16.2 24-3.6 5.6-7.4 11.4-11.2 16.9-.5.7-1 1-1.2 1-.3 0-1-.4-1.8-1.4-1.8-2.4-3.5-5.2-5.3-8.2l-.1-.1c-.6-1-1.2-2-1.9-3.1-20.4-32.7-42.2-59.2-66.8-81-26-23-53-38.9-82.5-48.8-22-7.4-44.6-11-69.3-11H269.3c-1.2 0-2.4 0-3.6.1v.1C125 10 10.8 122 10.4 260.3c-.3 6.8-.5 13.6-.4 20.6.9 40.6 7.3 81.8 19.1 122.4 18.6 64.1 48.6 127.5 91.6 193.8 37.6 57.9 83 115.9 138.7 177.3 58 63.9 124 129.3 202 200 11.3 10.2 24.6 15.6 38.4 15.6 13.9 0 27.2-5.4 38.6-15.7 8.6-7.7 17-15.6 25.2-23.1l.9-.8c2.5-2.3 4.9-4.6 7.4-6.9 77.2-71.4 142.3-138.1 199.1-203.9 58.8-68.1 104.4-131.9 139.6-195.1 42.1-75.7 66.9-146.8 75.7-217.1 5.1-41.1 4.9-72.7-.7-102.3z"
            fill="#ea0001"
          />
        </svg>
      </div>
    </StyledHeartCart>
  )
}

export default HeartCart

const StyledHeartCart = styled.div`
  pointer-events: all;
  .cart-heart-container {
    position: relative;
    display: flex;
    align-items: center;
    transition: 0.2s opacity ease-out;

    @media only screen and (max-width: ${vars.mobile}) {
      position: absolute;
      top: 5px;
      right: 50px;
      padding: 10px;
    }

    .total-count {
      letter-spacing: 0;
      pointer-events: none;
      position: absolute;
      color: white;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;

      @media only screen and (max-width: ${vars.mobile}) {
        font-size: 12px;
        margin-top: -1px;
      }

      &.whoah {
        font-size: 12px @media only screen and (max-width: ${vars.mobile}) {
          font-size: 10px;
        }
      }
    }
  }
  .cart-heart {
    box-sizing: border-box;
    width: 25px;
    @media only screen and (max-width: ${vars.mobile}) {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`
