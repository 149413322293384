import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby' /* eslint-disable */
import * as vars from '../variables.js'
import HeartCart from './heartCart'
import Subscribe from './subscribe'

const Menu = ({ path }) => {
  return (
    <StyledMenuWrapper>
      <div className="menu">
        {/* <div className= {` ${path == "" || path == "past" || path == "friends" ? "filter-container is-visible " : "filter-container"}`}> */}
        <div className="filter-container is-visible ">
          <div className={path == '' ? 'menu-item active' : 'menu-item'}>
            <Link to={`/`}>Current</Link>
          </div>
          <div className={path == 'past' ? 'menu-item active' : 'menu-item'}>
            <Link to={`/past/`}>Past</Link>
          </div>
          <div className={path == 'friends' ? 'menu-item active' : 'menu-item'}>
            <Link to={`/friends/`}>Friends</Link>
          </div>
        </div>
        <div className="link-container">
          <div
            className={path == 'info-care' ? 'menu-item active' : 'menu-item'}>
            <Link to={`/info-care/`}>Info</Link>
          </div>
          <Subscribe></Subscribe>
          <HeartCart></HeartCart>
        </div>
      </div>
    </StyledMenuWrapper>
  )
}

export default Menu

const StyledMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${vars.mobile}) {
    display: none;
  }

  .filter-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-transform: capitalize;
    display: none;

    &.is-visible {
      display: flex;
    }

    .menu-item {
      &.active {
        a {
          font-style: italic;
          color: black;
        }
      }

      &:not(:first-of-type) {
        margin-left: 50px;
        @media only screen and (max-width: 1000px) {
          margin-left: 30px;
        }
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    margin-left: 40px;

    .link-container {
      display: flex;
      align-items: center;

      .menu-item {
        margin-left: 20px;
      }
    }

    .menu-item a:hover {
      text-decoration: underline;
    }
  }

  .filter-item {
    color: rgb(165, 162, 162);
    font-size: 16px;
    outline: none;
    text-transform: uppercase;

    &.active {
      color: black;
    }
    &:not(:first-of-type) {
      margin-left: 50px;
      @media only screen and (max-width: 1000px) {
        margin-left: 30px;
      }
    }
  }

  .menu-item {
    align-items: center;
    color: black;
    display: flex;
    font-size: 16px;
    margin: 6px 0;
    text-transform: uppercase;
    transition: 0.2s transform;
  }

  .menu-item.active {
    a {
      font-style: italic;
    }
  }

  .menu-item:hover,
  .filter-item:hover {
    cursor: pointer;
  }

  .link-cluster {
    display: flex;
    color: #7a7a7a;
    text-transform: uppercase;
  }
`
