import {
  createCart,
  createCartItem,
  deleteCartItem,
  getCart,
  getProduct,
  updateCartItem,
} from '../shopify'
import { makeShopifyId } from '../shopify/utils'
import Cookies from 'js-cookie'

const COOKIE_CART_ID = 'HW_cartId'

export async function getOrCreateCart() {
  const cartId = Cookies.get(COOKIE_CART_ID)
  const cart = cartId ? await getCart(cartId) : await createCart()

  if (!cartId) {
    const newCartId = cart?.id
    if (newCartId) {
      Cookies.set(COOKIE_CART_ID, newCartId)
    }
  }

  return { cartId: cart?.id, cart }
}

export async function getItemAvailability({
  cartId,
  variantId,
  productId,
}: {
  cartId: string | null | undefined
  variantId: string | null | undefined
  productId: string | null | undefined
}) {
  if (!variantId) {
    return { inCartQuantity: 0, inStockQuantity: 0 }
  }

  if (!cartId) {
    const product = await getProduct(productId!)
    const inStockQuantity =
      product?.variants?.find((variant) => variant.id === variantId)
        ?.quantityAvailable ?? Number.POSITIVE_INFINITY
    return {
      inCartQuantity: 0,
      inStockQuantity,
    }
  }

  const cart = await getCart(cartId)
  const cartItem = cart?.items?.find(
    (item) => item.merchandise.id === variantId,
  )

  return {
    inCartQuantity: cartItem?.quantity ?? 0,
    inStockQuantity:
      cartItem?.merchandise.quantityAvailable ?? Number.POSITIVE_INFINITY,
  }
}

export async function addCartItem(variantId: string) {
  if (!variantId) return false

  const { cartId } = await getOrCreateCart()

  if (!cartId) return false

  await createCartItem(cartId, [
    { merchandiseId: makeShopifyId(variantId, 'ProductVariant'), quantity: 1 },
  ])

  return true
}

export async function removeCartItem(itemId: string) {
  const cartId = Cookies.get(COOKIE_CART_ID)
  if (!cartId) return false

  await deleteCartItem(cartId, [itemId])

  return true
}

export async function updateItemQuantity(payload: {
  itemId: string
  variantId: string
  quantity: number
  productId: string
}) {
  const cartId = Cookies.get(COOKIE_CART_ID)
  if (!cartId) return false

  const { itemId, variantId, quantity, productId } = payload

  if (quantity === 0) {
    await deleteCartItem(cartId, [itemId])
    return true
  }

  const itemAvailability = await getItemAvailability({
    cartId,
    variantId,
    productId,
  })
  if (!itemAvailability || quantity > itemAvailability.inStockQuantity) {
    return {
      ok: false,
      message: 'This product is out of stock',
    }
  }

  await updateCartItem(cartId, [
    { id: itemId, merchandiseId: variantId, quantity },
  ])

  return true
}
